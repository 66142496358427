import React from "react";
import { AnimatedCard } from "../../components/Screens/About/AnimatedCard";
import { GridGallery } from "../../components/Screens/About/GridGallery";
import { PageHeader } from "../../components/Atomics/PageHeader";
import Layouts from "../../components/Layouts";
import Seo from "../../components/Seo";
import { TimeLineSection } from "../../components/Screens/About/TimeLine";
import { Cta } from "../../components/Cta";
import { Leaders } from "../../components/Screens/About/Leaders";
import { graphql } from "gatsby";

const AboutPageArabic = ({ data }) => {
	const heroImages = data.page.heroAboutAr.heroImagesAboutAr;
	const page = data.page;
	const animatedCards = {
		cardOne: {
			miniHeader: page.animatedCardsAboutAr.cardsAboutAr.firstCard.miniHeader,
			header: (
				<div className="2xl:w-7/12 mx-auto  text-center">
					{page.animatedCardsAboutAr.cardsAboutAr.firstCard.header}
				</div>
			),
			text: (
				<>
					<p>{page.animatedCardsAboutAr.cardsAboutAr.firstCard.text}</p>
				</>
			),
		},
		cardTwo: {
			miniHeader: page.animatedCardsAboutAr.cardsAboutAr.secondCard.miniHeader,
			header: (
				<div className="2xl:w-8/12 mx-auto  text-center">
					{page.animatedCardsAboutAr.cardsAboutAr.secondCard.header}
				</div>
			),
			text: (
				<>
					<p>{page.animatedCardsAboutAr.cardsAboutAr.secondCard.text}</p>
				</>
			),
		},
	};
	return (
		<>
			<Seo title="عن كاشات" />
			<Layouts lang={"ar"} dir="rtl">
				<PageHeader
					miniHeader={page.heroAboutAr.miniHeaderAboutAr}
					header={
						<div className="2xl:w-9/12 mx-auto">
							{page.heroAboutAr.headerAboutAr}
						</div>
					}
				/>
				<GridGallery images={heroImages} />
				<AnimatedCard cards={animatedCards} />
				<TimeLineSection
					items={page.timelineAboutAr.itemsTimelineAr}
					header="مراحل مهمة فى رحلتنا"
				/>
				<Leaders
					leaders={page.leadersAboutAr.leadersAboutAr}
					miniHeader="القيادة"
					header="مجلس إدارة كاشات"
				/>
				<Cta
					lang="ar"
					miniHeader="أول مقدم للخدمات المالية متناهية الصغر في الشرق الأوسط"
					header="مهمتنا تمكين الوصول الى الإمكانيات المالية الرقمية"
					subHeader="كاشات هي أول خطوة للشمول المالي للمصريين الغير متعاملين مع البنوك عن طريق توفير تمويلات انتاجية قصيرة المدى وسريعة"
				/>
			</Layouts>
		</>
	);
};

export default AboutPageArabic;
export const pageQu = graphql`
	query ArabicAbout {
		page: wpPage(databaseId: { eq: 352 }) {
			title
			heroAboutAr {
				miniHeaderAboutAr
				headerAboutAr
				heroImagesAboutAr {
					one {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
					two {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
					three {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
					four {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
				}
			}
			animatedCardsAboutAr {
				cardsAboutAr {
					firstCard {
						miniHeader
						header
						text
					}
					secondCard {
						miniHeader
						header
						text
					}
				}
			}
			timelineAboutAr {
				itemsTimelineAr {
					date
					text
					logo {
						sourceUrl
					}
				}
			}
			leadersAboutAr {
				leadersAboutAr {
					name
					title
					image {
						sourceUrl
					}
					linkedinUrl
				}
			}
		}
	}
`;
